<template>
    <BaseSection
        v-if="image || richText"
        width="content"
    >
        <div
            class="text-image"
            :class="flipped ? 'text-image--flipped' : ''"
        >
            <ImageAsset
                v-if="image"
                class="text-image__image"
                :class="rounded ? 'text-image__image--rounded': ''"
                :image="image"
                sizes="370px"
                alt=""
            />
            <RichText
                v-if="richText"
                class="text-image__text"
                :text="richText || undefined"
            />
        </div>
    </BaseSection>
</template>

<script>
export default {
    props: {
        image: {
            type: Object,
            required: false,
            default: () => { return {}; }
        },
        richText: {
            type: String,
            required: false,
            default: ''
        },
        rounded: {
            type: Boolean,
            required: false,
            default: false
        },
        flipped: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="less" src="./TextImage.less"></style>
